<template>
  <div>
    <img src="../../../public/images/qt/app/veepai1.png" width="100%" height="100%">
    <img src="../../../public/images/qt/app/veepai2.png" width="100%" height="100%">
    <img src="../../../public/images/qt/app/veepai3.png" width="100%" height="100%">
    <img src="../../../public/images/qt/app/veepai4.png" width="100%" height="100%">
    <img src="../../../public/images/qt/app/veepai5.png" width="100%" height="100%">
  </div>
</template>

<script>
export default {
  name: "APP下载"
}
</script>

<style scoped>

</style>